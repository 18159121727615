<template>
  <Form
    @submit="onSubmitSearch"
    id="kt_account_profile_details_form"
    class="form"
    novalidate="novalidate"
  >
    <div class="row" style="padding: 10px">
      <div class="col-3">
        <BaseSelectNl name="title"  :data="titles" k="name" />
      </div>
      <div class="col-6">
        <BaseInputNl label="ค้นหา" name="value" type="text" />
      </div>
      <div class="col-3">
        <button
          type="submit"
          style="width: 100%"
          ref="submitButton"
          class="btn btn-primary"
        >
          <span class="indicator-label"> ค้นหา </span>
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import BaseInputNl from "@/components/modals/BaseInputNl.vue";
import BaseSelectNl from "@/components/modals/BaseSelectNl.vue";
import { defineComponent } from "vue";
import { Form } from "vee-validate";
export default defineComponent({
  name: "appeal_wait",
  components: {
    Form,
    BaseInputNl,
    BaseSelectNl,
  },
  props: ["titles"],
  setup(props, { emit }) {
    const onSubmitSearch = (val) => {
      emit("onSearch", val.title, val.value);
    };

    return {
      onSubmitSearch,
    };
  },
});
</script>

<style></style>
