<template>
      <Field
        v-if="as"
        :name="name"
        class="form-control form-control-lg form-control-solid"
        :placeholder="label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :as="as"
        rows="6"
        :disabled="disabled"
      />
      <Field
        v-else
        v-bind="$attrs"
        :name="name"
        class="form-control form-control-lg form-control-solid"
        :placeholder="label"
        :value="modelValue"
        :type="type"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage :name="name" />
        </div>
      </div>
</template>

<script>
import { ErrorMessage, Field } from "vee-validate";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    as: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled:{
       type: Boolean,
      default: false,
    }
  },
  components: {
    ErrorMessage,
    Field,
  },
};
</script>

<style>
</style>